import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useRef, useState } from 'react'
import { Link as GatsbyLink, graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { Image } from '../component/Images'
import { Box, Button, Hidden, Typography } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
// import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import SortIcon from '@material-ui/icons/Sort'
import { Layout } from '../component/Layout'
import TeamSidebar from '../component/page_component/agency/team/TeamSidebar'
import { Avatar } from '../component/Avatar'
import AgentDialog from '../component/page_component/agency/team/AgentDialog'
import MobileSidebar from '../component/page_component/agency/team/mobileSidebar'
import { CustomButton } from '../component/CustomButton'
import { Logo } from '../component/topBar/logo'
import world_map from '../images/Map_Monde_Kretz.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '70px 0em 0em 0em',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '1em 1em',
      // marginTop: '70px',
      zIndex: 1102,
      backgroundColor: '#fff',
    },
  },
  headerSection: {
    flexBasis: '100%',
    justifyContent: 'space-between',
    paddingInline: '2rem',
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: 'unset',
      paddingInline: '0 2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingInline: '0 0rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  teamTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(80),
    textAlign: 'left',
    textTransform: 'uppercase',
    display: 'flex',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  },
  sidebarBtn: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  returnBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: `'Gotham book', serif`,
    fontSize: '12px',
    fontWeight: 700,
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    [theme.breakpoints.between('xs', 'sm')]: {
      textTransform: 'uppercase',
      fontFamily: `'Gotham Medium', serif`,
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '13.26px',
      padding: '20px 0',
    },
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1em',
    borderTop: '1px solid #E7E7E7',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  content: {
    flexGrow: 1,
    flex: 1,
  },
  agentList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingTop: '1em',
  },
  sidebarContainer: {
    flexShrink: 0,
    borderRight: '1px solid #eee',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  filtreparPaysCTA: {
    fontFamily: `'Gotham Medium', serif`,
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#1d1d1b',
  },
}))

export const AgencyTeam: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()
  const classes = useStyles()
  const [agents, setAgents] = useState<string[] | null>(null)
  const [selectedAgent, setSelectedAgent] = useState<any | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [selectedCityName, setSelectedCityName] = useState('')

  const smallArray = [
    { Ordre: 1, Prénom: 'Olivier', Nom: 'Kretz' },
    { Ordre: 2, Prénom: 'Sandrine', Nom: 'Kretz' },
    { Ordre: 3, Prénom: 'Martin', Nom: 'Kretz' },
    { Ordre: 4, Prénom: 'Valentin', Nom: 'Kretz' },
    { Ordre: 5, Prénom: 'Raphaël', Nom: 'Kretz' },
    { Ordre: 6, Prénom: 'Vanessa', Nom: 'VIALLET' },
    { Ordre: 7, Prénom: 'Audrey', Nom: 'RENNESSON' },
    { Ordre: 8, Prénom: 'Geoffroy', Nom: 'BROSSOLLET' },
    { Ordre: 9, Prénom: 'Jeanne', Nom: 'MIAMONA' },
    { Ordre: 10, Prénom: 'Auriane', Nom: 'GALZIN' },
    { Ordre: 11, Prénom: 'Claudia', Nom: 'DE ANDRADE' },
    { Ordre: 12, Prénom: 'Jean-Baptiste', Nom: 'BOISEL' },
    { Ordre: 13, Prénom: 'Charles', Nom: 'NAFFAH' },
    { Ordre: 14, Prénom: 'Nathalie', Nom: 'NORMAND' },
    { Ordre: 15, Prénom: 'Damien', Nom: 'BERTRAND' },
    // { Ordre: 16, Prénom: 'Elisabeth', Nom: 'KOSULINA' },
    // { Ordre: 17, Prénom: 'Elodie', Nom: 'STIENLET' },
    { Ordre: 18, Prénom: 'Timothée', Nom: 'MENGELLE' },
    { Ordre: 19, Prénom: 'Angélique', Nom: 'DUSSOLLIER' },
    { Ordre: 20, Prénom: 'Arnaud', Nom: 'DIOT' },
    { Ordre: 21, Prénom: 'Damien', Nom: 'VERPLAETSE' },
    // { Ordre: 22, Prénom: 'Alexandre', Nom: 'DECAMPS' },
    { Ordre: 23, Prénom: 'Marie', Nom: 'BASTIDE' },
    { Ordre: 24, Prénom: 'Valerie', Nom: 'CHARVY' },
    { Ordre: 25, Prénom: 'Maxime', Nom: 'lecomte flament' },
    { Ordre: 26, Prénom: 'Pauline', Nom: 'de cock' },
    { Ordre: 27, Prénom: 'Albane', Nom: 'de larminat' },
    // { Ordre: 28, Prénom: 'Mathieu', Nom: 'BOUCHOUT' },
    // { Ordre: 29, Prénom: 'Adrian', Nom: 'BOUCHOUT' },
    // { Ordre: 30, Prénom: 'Fabrice', Nom: 'CORREIA' },
    // { Ordre: 31, Prénom: 'Xavier', Nom: 'DUCROS' },
    { Ordre: 32, Prénom: 'Lucie', Nom: 'COQUEREAU' },
    { Ordre: 33, Prénom: 'Yasmina', Nom: 'DIOT' },
    { Ordre: 34, Prénom: 'Amandine', Nom: 'DU FRAYSSEIX' },
    { Ordre: 35, Prénom: 'Victor', Nom: 'CHOPPE' },
    // { Ordre: 36, Prénom: 'Janet', Nom: 'CRETIN' },
    // { Ordre: 37, Prénom: 'Chris', Nom: 'CRETIN' },
    { Ordre: 38, Prénom: 'Charlotte', Nom: 'BORIE' },
    // { Ordre: 39, Prénom: 'Baptiste', Nom: 'DUBUC' },
    { Ordre: 40, Prénom: 'Marie Caroline', Nom: 'DELLUS' },
    { Ordre: 41, Prénom: 'Julien', Nom: 'DREOSSI' },
    { Ordre: 42, Prénom: 'Julie', Nom: 'DREOSSI' },
    { Ordre: 43, Prénom: 'Elodie', Nom: 'LE VALLEGANT' },
    { Ordre: 44, Prénom: 'Baptiste', Nom: 'DEBOTTE' },
    { Ordre: 45, Prénom: 'Julien', Nom: 'POUILLOUX' },
    { Ordre: 46, Prénom: 'Vincent', Nom: 'MATHERON' },
    { Ordre: 47, Prénom: 'Romain', Nom: 'DJERRAF' },
    { Ordre: 48, Prénom: 'Roxanne', Nom: 'GUYONVARCH' },
    { Ordre: 49, Prénom: 'Matthieu', Nom: 'VANDEPITTE' },
    { Ordre: 50, Prénom: 'Cloé', Nom: 'CAMET-LASSALLE' },
    { Ordre: 51, Prénom: 'Johan', Nom: 'AUBRY' },
    { Ordre: 52, Prénom: 'Lauriane', Nom: 'LIVET' },
    { Ordre: 53, Prénom: 'Benjamin', Nom: 'GAUTIER' },
    { Ordre: 54, Prénom: 'Claude', Nom: 'MARENGHI' },
    { Ordre: 55, Prénom: 'Brigitte', Nom: 'KAHAN' },
    { Ordre: 56, Prénom: 'Olivier', Nom: 'BONNEMAISON' },
    { Ordre: 57, Prénom: 'Melissa', Nom: 'SAILLY' },
    { Ordre: 58, Prénom: 'Celine', Nom: 'CHATAIN' },
    { Ordre: 59, Prénom: 'Julia', Nom: 'PEROZ' },
    { Ordre: 60, Prénom: 'Sophie', Nom: 'GIULIANI' },
    { Ordre: 61, Prénom: 'Gaëlle', Nom: "Le Bourc'h" },
    { Ordre: 62, Prénom: 'Emmanuelle', Nom: 'CORNIC' },
    { Ordre: 63, Prénom: 'Kevin', Nom: 'JACQUES' },
    { Ordre: 64, Prénom: 'Clara', Nom: 'WARTSKI' },
    { Ordre: 65, Prénom: 'Julie', Nom: 'DUBOC' },
    { Ordre: 66, Prénom: 'Katia', Nom: 'PUTINE' },
    // { Ordre: 67, Prénom: 'Lamia', Nom: 'AMMAR' },
    { Ordre: 68, Prénom: 'Sandrine', Nom: 'BOREL-GIRAUD' },
    { Ordre: 69, Prénom: 'Julien', Nom: 'GERARD' },
    { Ordre: 70, Prénom: 'Eleonore', Nom: 'BOURION' },
    { Ordre: 71, Prénom: 'William', Nom: 'PAGE' },
    { Ordre: 72, Prénom: 'Amandine', Nom: 'GIRARD' },
    // { Ordre: 73, Prénom: 'Vanina', Nom: 'SANTINI' },
    { Ordre: 74, Prénom: 'Bruno', Nom: 'RIGOUT' },
    { Ordre: 75, Prénom: 'Laurence', Nom: 'MOUREAU' },
    // { Ordre: 76, Prénom: 'Valentin', Nom: 'LAMONT' },
    // { Ordre: 77, Prénom: 'Olga', Nom: 'SEMENOVA' },
    // { Ordre: 78, Prénom: 'Tania', Nom: 'TIR' },
    // { Ordre: 79, Prénom: 'Romain', Nom: 'SIMON' },
    // { Ordre: 80, Prénom: 'Leslie', Nom: 'CALLAREC' },
    // { Ordre: 81, Prénom: 'Nicolas', Nom: 'AYRAUD' },
    // { Ordre: 82, Prénom: 'Caroline', Nom: 'MARTINS' },
    { Ordre: 83, Prénom: 'Louis', Nom: 'Kretz' },
    { Ordre: 84, Prénom: 'Adriana', Nom: 'Pinos' },
    { Ordre: 85, Prénom: 'Camille', Nom: 'Richard' },
    { Ordre: 86, Prénom: 'Begoña', Nom: 'Vázquez Orozco' },
    { Ordre: 87, Prénom: 'Jorge', Nom: 'De Andrés' },
    { Ordre: 88, Prénom: 'Loreto', Nom: 'Sierra Garcia' },
    { Ordre: 89, Prénom: 'Lucía', Nom: 'González Casal' },
    { Ordre: 90, Prénom: 'Nicolle', Nom: 'Balanica' },
    { Ordre: 91, Prénom: 'Carolina', Nom: 'Villarroel' },
    { Ordre: 92, Prénom: 'Aleksandra', Nom: 'Collado' },
    { Ordre: 93, Prénom: 'Sophie', Nom: 'Corvaisier' },
    { Ordre: 94, Prénom: 'Youcef', Nom: 'Rahal' },
    { Ordre: 95, Prénom: 'Tamani', Nom: 'Sadoun' },
    { Ordre: 96, Prénom: 'Guilhem', Nom: 'Gauran' },
    { Ordre: 97, Prénom: 'Aline', Nom: 'Arnould' },
    { Ordre: 98, Prénom: 'Daphné', Nom: 'Chaouat' },
    { Ordre: 99, Prénom: 'Matthieu', Nom: 'Bouchout' },
    { Ordre: 100, Prénom: 'Fabrice', Nom: 'Correia' },
    { Ordre: 101, Prénom: 'Adrian', Nom: 'Bouchout' },
    { Ordre: 102, Prénom: 'Alexandre', Nom: 'Decamps' },
    { Ordre: 103, Prénom: 'Lamia', Nom: 'Ammar' }
  ]
  // Create a ref for the agents container
  const agentsContainerRef = useRef<HTMLDivElement | null>(null)

  const {
    allTeam: { edges },
  } = useStaticQuery(graphql`
    query MyQuery {
      allTeam {
        edges {
          node {
            id
            lname
            fname
            phone
            email
            photo {
              portraitSquare
            }
            destination

            descriptionEn
            descriptionEs
            descriptionFr
            descriptionPt

            titreEn
            titreEs
            titreFr
            titrePt
          }
        }
      }
    }
  `)

  const orderedCountries = ['FR', 'ES', 'US', 'MC', 'BL', 'GR', 'BR', 'MA']

  const transformData = (edges: any[]) => {
    // Create a lookup map from smallArray
    const orderMap: Record<string, number> = smallArray.reduce((acc: Record<string, number>, person) => {
      acc[`${person.Prénom?.toLowerCase()} ${person.Nom?.toLowerCase()}`] = person.Ordre
      return acc
    }, {})


    const grouped = edges.reduce((acc: Record<string, { regions: Record<string, any[]>, cities: Record<string, any[]> }>, person: any) => {
      const parts = person.node.destination.split('/')
      const [country, region = 'all', city = 'all'] = parts

      if (!orderedCountries.includes(country)) return acc

      if (!acc[country]) acc[country] = { regions: {}, cities: {} }
      if (!acc[country].regions[region]) acc[country].regions[region] = []
      if (!acc[country].cities[city]) acc[country].cities[city] = []

      acc[country].cities[city].push(person)
      // Add person to "all" only if they're not already under a specific city
      if (city !== 'all') {
        if (!acc[country].cities['all']) acc[country].cities['all'] = []
        acc[country].cities['all'].push(person)
      }

      acc[country].regions[region].push(person)

      return acc
    }, {})

    return Object.entries(grouped)
      .sort(([a], [b]) => {
        const indexA = orderedCountries.indexOf(a)
        const indexB = orderedCountries.indexOf(b)

        // Handle missing countries (place them at the end)
        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
      })
      .map(([country, data]) => ({
        country,
        regions: Object.entries(data.regions).map(([region, agents]) => ({
          region,
          agents: agents.sort((a: any, b: any) => {
            const orderA =
              orderMap[`${a.node.fname?.toLowerCase()} ${a.node.lname?.toLowerCase()}`] || Infinity
            const orderB =
              orderMap[`${b.node.fname?.toLowerCase()} ${b.node.lname?.toLowerCase()}`] || Infinity

            return orderA - orderB
          }),
        })),
        cities: Object.entries(data.cities).map(([city, agents]) => ({
          city,
          agents: agents.sort((a: any, b: any) => {
            const orderA =
              orderMap[`${a.node.fname?.toLowerCase()} ${a.node.lname?.toLowerCase()}`] || Infinity
            const orderB =
              orderMap[`${b.node.fname?.toLowerCase()} ${b.node.lname?.toLowerCase()}`] || Infinity

            return orderA - orderB
          }),
        })),
      }))
  }

  const handleCitySelect = (city: any) => {
    setSelectedCityName(city.name)
    setAgents(city.agents)
    // Scroll to top of agents container
    if (agentsContainerRef.current) {
      agentsContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  const handleAgentSelect = (agent: any) => {
    setSelectedAgent(agent)
    setDialogOpen(true)
  }
  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedAgent(null)
  }
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev)
  }

  return (
    <Layout
      showFlotingMenu={false}
      maxWidth={false}
      location={location}
      title={intl.formatMessage({ id: 'Kretz', defaultMessage: 'Kretz' })}
    >
      <Grid container className={classes.root} ref={agentsContainerRef}>
        <Grid item container xs={12} className={classes.headerSection}>
          {/* web version */}
          <Hidden smDown>
            <Grid item xs={6} className={classes.teamTitle}>
              {intl.formatMessage({ id: 'link.team' })}
            </Grid>

            <Grid item xs={6} className={classes.returnBtn}>
              {' '}
              <GatsbyLink to={`/${intl.locale}/agency`} color="inherit" className={classes.link}>
                <KeyboardBackspaceIcon /> {intl.formatMessage({ id: 'back' })}
              </GatsbyLink>
            </Grid>
          </Hidden>
          {/* mobile version */}
          <Hidden smUp>
            <Grid item container xs={12} className={classes.headerSection}>
              <Grid item xs={3}>
                {/* <CustomButton
                  icon={() => <MenuIcon />}
                  borderless
                  iconPosition="end"
                  displayIcon
                  variant=""
                  onClick={toggleSidebar}
                /> */}
                <GatsbyLink to={`/${intl.locale}/agency`} color="inherit" className={classes.link}>
                  <CloseIcon />
                </GatsbyLink>
              </Grid>
              <Grid item xs={6} alignItems="center" justifyContent="center">
                <Logo />
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Hidden>
        </Grid>

        <Grid item container xs={12} className={classes.mainContainer}>
          <Hidden smUp>
            <MobileSidebar
              countries={transformData(edges)}
              onCitySelect={handleCitySelect}
              isOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              selectedCity={selectedCityName}
            />
          </Hidden>
          <Hidden smDown>
            <Grid item md={3} className={classes.sidebarContainer}>
              <TeamSidebar
                onCitySelect={handleCitySelect}
                countries={transformData(edges)}
                selectedCity={selectedCityName}
              />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingBlock: '1rem',
                position: 'sticky',
                top: 0,
                backgroundColor: '#fff',
                width: '100dvw',
              }}
            >
              <Button onClick={toggleSidebar}>
                <Typography className={classes.filtreparPaysCTA}>
                  {intl.formatMessage({ id: 'new.Equipe_2' })}
                </Typography>
              </Button>
              <SortIcon fontSize="small" />
            </Box>
          </Hidden>
          <Grid item container md={9} xs={12} className={classes.agentList}>
            {agents ? (
              <Grid item xs={12}>
                {agents && agents.length > 0 ? (
                  <Grid container spacing={2}>
                    {agents.map((agent: any, index: number) => (
                      <Grid item xs={6} sm={4} md={3} key={index}>
                        <Avatar agent={agent} onClick={() => handleAgentSelect(agent)} hideNumber />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <p style={{ textAlign: 'center' }}>Aucun agent disponible pour cette ville.</p>
                )}
              </Grid>
            ) : (
              <img
                src={world_map}
                alt="Image"
                style={{ height: '100%', width: '100%', zIndex: 0, objectFit: 'contain' }}
              />
            )}
            {selectedAgent && (
              <AgentDialog open={dialogOpen} onClose={handleCloseDialog} agent={selectedAgent} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default AgencyTeam
